import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import { ChainId, PENDLE_PT_MNT_OCT_2024_ADDRESSES, PENDLE_PT_MNT_OCT_2024_ISOLATION_MODE_ADDRESSES } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { getOptionally } from '../special-assets-util-functions'
import { calculatePendlePtMntOct2024ProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { PENDLE_PT_DOCS_LINK } from './constants'

const decimals = 18

export const PT_MNT_OCT_2024_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.dPtMethDecember2024,
  safeSymbol: SpecialAssetSymbol.dPtMethDecember2024.replace('-', '_'),
  cleanSymbol: 'PT-MNT',
  cleanName: 'PT MNT 2024-10-03',
  hasExternalRewards: false,
  documentationUrl: PENDLE_PT_DOCS_LINK,
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: PENDLE_PT_MNT_OCT_2024_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        PENDLE_PT_MNT_OCT_2024_ISOLATION_MODE_ADDRESSES[chainId],
        address =>
          new Token(
            chainId,
            address,
            decimals,
            SpecialAssetSymbol.dPtMethDecember2024,
            'Dolomite Isolation: PT USDe 26DEC2024',
          ),
      ),
    getUnderlyingToken: getPendlePtMntUnderlyingToken,
    getUnderlyingSpenderToken: getPendlePtMntUnderlyingToken,
    allowableDebtTokens: () => [],
    allowableCollateralTokens: () => [],
    remapAccountAddress: calculatePendlePtMntOct2024ProxyVaultAddress,
    isAsync: false,
  },
})

function getPendlePtMntUnderlyingToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    PENDLE_PT_MNT_OCT_2024_ADDRESSES[chainId],
    address => new Token(chainId, address, decimals, 'PT-MNT', 'PT MNT 26DEC2024'),
  )
}
