import { ChainId, ChainIdMap } from '../chainId'
import { Token } from '@dolomite-exchange/v2-sdk'
import ModuleDeployments from '@dolomite-exchange/modules-deployments/src/deploy/deployments.json'
import { useMemo } from 'react'
import { useActiveWeb3React } from '../../hooks'
import invariant from 'tiny-invariant'
import { GraphqlCall } from '../../state/graphql/actions'
import { GLP_SPECIAL_ASSET } from './assets/GLP_SPECIAL_ASSET'
import { MAGIC_GLP_SPECIAL_ASSET } from './assets/MAGIC_GLP_SPECIAL_ASSET'
import { PLV_GLP_SPECIAL_ASSET } from './assets/PLV_GLP_SPECIAL_ASSET'
import { J_USDC_SPECIAL_ASSET } from './assets/J_USDC_SPECIAL_ASSET'
import { PT_GLP_MAR_2024_SPECIAL_ASSET } from './assets/PT_GLP_MAR_2024_SPECIAL_ASSET'
import { YT_GLP_MAR_2024_SPECIAL_ASSET } from './assets/YT_GLP_MAR_2024_SPECIAL_ASSET'
import { WST_ETH_SPECIAL_ASSET } from './assets/WST_ETH_SPECIAL_ASSET'
import { R_ETH_SPECIAL_ASSET } from './assets/R_ETH_SPECIAL_ASSET'
import { WETH_SPECIAL_ASSET } from './assets/WETH_SPECIAL_ASSET'
import { WBTC_SPECIAL_ASSET } from './assets/WBTC_SPECIAL_ASSET'
import { USDC_SPECIAL_ASSET } from './assets/USDC_SPECIAL_ASSET'
import { ARB_SPECIAL_ASSET } from './assets/ARB_SPECIAL_ASSET'
import { LINK_SPECIAL_ASSET } from './assets/LINK_SPECIAL_ASSET'
import { PT_R_ETH_JUN_2025_SPECIAL_ASSET } from './assets/PT_R_ETH_JUN_2025_SPECIAL_ASSET'
import { PT_WST_ETH_JUN_2024_SPECIAL_ASSET } from './assets/PT_WST_ETH_JUN_2024_SPECIAL_ASSET'
import { PT_WST_ETH_JUN_2025_SPECIAL_ASSET } from './assets/PT_WST_ETH_JUN_2025_SPECIAL_ASSET'
import { GMX_SPECIAL_ASSET } from './assets/GMX_SPECIAL_ASSET'
import { D_ARB_SPECIAL_ASSET } from './assets/D_ARB_SPECIAL_ASSET'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { toChecksumAddress } from '../../utils/toChecksumAddress'
import { PT_WE_ETH_APR_2024_SPECIAL_ASSET } from './assets/PT_WE_ETH_APR_2024_SPECIAL_ASSET'
import { GM_ARB_USD_SPECIAL_ASSET } from './assets/GM_ARB_USD_SPECIAL_ASSET'
import { GM_BTC_USD_SPECIAL_ASSET } from './assets/GM_BTC_USD_SPECIAL_ASSET'
import { GM_LINK_USD_SPECIAL_ASSET } from './assets/GM_LINK_USD_SPECIAL_ASSET'
import { GM_ETH_USD_SPECIAL_ASSET } from './assets/GM_ETH_USD_SPECIAL_ASSET'
import { PT_EZ_ETH_JUN_2024_SPECIAL_ASSET } from './assets/PT_EZ_ETH_JUN_2024_SPECIAL_ASSET'
import { PT_GLP_SEP_2024_SPECIAL_ASSET } from './assets/PT_GLP_SEP_2024_SPECIAL_ASSET'
import { PT_WE_ETH_JUN_2024_SPECIAL_ASSET } from './assets/PT_WE_ETH_JUN_2024_SPECIAL_ASSET'
import {
  DPX_ADDRESSES,
  GM_AAVE_USD_ISOLATION_MODE_ADDRESSES,
  GM_ARB_USD_ISOLATION_MODE_ADDRESSES,
  GM_BTC_ISOLATION_MODE_ADDRESSES,
  GM_BTC_USD_ISOLATION_MODE_ADDRESSES,
  GM_DOGE_USD_ISOLATION_MODE_ADDRESSES,
  GM_ETH_ISOLATION_MODE_ADDRESSES,
  GM_ETH_USD_ISOLATION_MODE_ADDRESSES,
  GM_GMX_USD_ISOLATION_MODE_ADDRESSES,
  GM_LINK_USD_ISOLATION_MODE_ADDRESSES,
  GM_SOL_USD_ISOLATION_MODE_ADDRESSES,
  GM_UNI_USD_ISOLATION_MODE_ADDRESSES,
  GM_WST_ETH_USD_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_GLP_MAR_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_YT_GLP_MAR_2024_ISOLATION_MODE_ADDRESSES,
  PLV_GLP_ISOLATION_MODE_ADDRESSES,
  S_GLP_ADDRESSES,
  USDY,
} from '../index'
import { EZ_ETH_SPECIAL_ASSET } from './assets/EZ_ETH_SPECIAL_ASSET'
import { WE_ETH_SPECIAL_ASSET } from './assets/WE_ETH_SPECIAL_ASSET'
import { GM_BTC_SPECIAL_ASSET } from './assets/GM_BTC_SPECIAL_ASSET'
import { GM_ETH_SPECIAL_ASSET } from './assets/GM_ETH_SPECIAL_ASSET'
import { WMNT_SPECIAL_ASSET } from './assets/WMNT_SPECIAL_ASSET'
import { USDT_SPECIAL_ASSET } from './assets/USDT_SPECIAL_ASSET'
import { METH_SPECIAL_ASSET } from './assets/METH_SPECIAL_ASSET'
import { GM_UNI_USD_SPECIAL_ASSET } from './assets/GM_UNI_USD_SPECIAL_ASSET'
import { W_USDM_SPECIAL_ASSET } from './assets/W_USDM_SPECIAL_ASSET'
import { RS_ETH_SPECIAL_ASSET } from './assets/RS_ETH_SPECIAL_ASSET'
import { GRAI_SPECIAL_ASSET } from './assets/GRAI_SPECIAL_ASSET'
import { PT_EZ_ETH_SEP_2024_SPECIAL_ASSET } from './assets/PT_EZ_ETH_SEP_2024_SPECIAL_ASSET'
import { PT_WE_ETH_SEP_2024_SPECIAL_ASSET } from './assets/PT_WE_ETH_SEP_2024_SPECIAL_ASSET'
import { PT_USDE_JUL_2024_SPECIAL_ASSET } from './assets/PT_USDE_JUL_2024_SPECIAL_ASSET'
import { PT_RS_ETH_SEP_2024_SPECIAL_ASSET } from './assets/PT_RS_ETH_SEP_2024_SPECIAL_ASSET'
import { InterestRatePart } from '../../types/InterestRatePart'
import { USDY_SPECIAL_ASSET } from './assets/USDY_SPECIAL_ASSET'
import useIpGeolocation from '../../hooks/useIpGeolocation'
import { WOETH_SPECIAL_ASSET } from './assets/WOETH_SPECIAL_ASSET'
import { USDM_SPECIAL_ASSET } from './assets/USDM_SPECIAL_ASSET'
import { PT_USDE_DEC_2024_SPECIAL_ASSET } from './assets/PT_USDE_DEC_2024_SPECIAL_ASSET'
import { D_WMNT_SPECIAL_ASSET } from './assets/D_WMNT_SPECIAL_ASSET'
import { PT_METH_DEC_2024_SPECIAL_ASSET } from './assets/PT_METH_DEC_2024_SPECIAL_ASSET'
import { GM_AAVE_USD_SPECIAL_ASSET } from './assets/GM_AAVE_USD_SPECIAL_ASSET'
import { GM_GMX_USD_SPECIAL_ASSET } from './assets/GM_GMX_USD_SPECIAL_ASSET'
import { GM_DOGE_USD_SPECIAL_ASSET } from './assets/GM_DOGE_USD_SPECIAL_ASSET'
import { GM_SOL_USD_SPECIAL_ASSET } from './assets/GM_SOL_USD_SPECIAL_ASSET'
import { GM_WST_ETH_USD_SPECIAL_ASSET } from './assets/GM_WST_ETH_USD_SPECIAL_ASSET'
import { PT_MNT_OCT_2024_SPECIAL_ASSET } from './assets/PT_MNT_OCT_2024_SPECIAL_ASSET'

export interface RewardInfo {
  getOutsideAprCallData: (chainId: ChainId) => GraphqlCall
  getExtraOutsideAprCallData?: (chainId: ChainId) => GraphqlCall
  parser?: (data: any) => InterestRatePart[]
}

export interface IsolationModeInfo {
  /**
   * @description Gets the wrapped vault token that this special asset represents. All data must match exactly what's
   *              shown on-chain For example, for dfsGLP, this is the dfsGLP token.
   *
   * @param chainId The ID of the chain the user is on
   * @return The wrapped vault token or undefined if it doesn't exist on the given chainId
   */
  getWrappedToken: (chainId: ChainId) => Token | undefined
  /**
   * @description Useful for getting the actual token that underpins a wrapped vault token. All data must match exactly
   *              what's shown on-chain For example, for dfsGLP, this is the dfsGLP token.
   *
   * @param chainId The ID of the chain the user is on
   * @return The underlying token or undefined if it doesn't exist on the given chainId
   */
  getUnderlyingToken: (chainId: ChainId) => Token | undefined
  /**
   * @description Useful for getting the token that is spent to transfer the underlying token. For example, for dfsGLP,
   *              this is the sGLP token.
   *
   * @param chainId The ID of the chain the user is on
   * @return The underlying spender token or undefined if it doesn't exist on the given chainId
   */
  getUnderlyingSpenderToken: (chainId: ChainId) => Token | undefined
  /**
   * @param chainId The ID of the chain the user is on
   * @return  Gets the allowable collateral tokens for a given chainId. Returns an empty array if all tokens are
   *          allowed. Returns undefined if the asset does not exist on the given chainId.
   */
  allowableCollateralTokens: (chainId: ChainId) => Token[] | undefined
  /**
   * @param chainId The ID of the chain the user is on
   * @return  Gets the allowable debt tokens for a given chainId. Returns an empty array if all tokens are allowed.
   *          Returns undefined if the asset does not exist on the given chainId.
   */
  allowableDebtTokens: (chainId: ChainId) => Token[] | undefined
  /**
   * @description Function that remaps the account address to the proxy vault address for the given chainId.
   *              Returns undefined if the asset does not exist on the given chainId.
   */
  remapAccountAddress?: RemapAddressFunction
  /**
   * Returns an array of valid output tokens for zapping when this special asset is the output token
   *
   * @param {ChainId} chainId - The chain ID for which to retrieve the valid input tokens.
   * @returns {Token[] | undefined} An array of valid input tokens for the given chain ID, or undefined if no valid tokens are found.
   */
  allowableInputTokensForZap?: (chainId: ChainId) => Token[] | undefined
  /**
   * Returns an array of valid output tokens for zapping when this special asset is the input token
   *
   * @param {ChainId} chainId - The chain ID for which to retrieve the valid output tokens.
   * @returns {Token[] | undefined} An array of valid output tokens for the given chain ID, or undefined if no valid tokens are found.
   */
  allowableOutputTokensForZap?: (chainId: ChainId) => Token[] | undefined
  /**
   * @description Function that calculates the expiration timestamp for the given isolation mode asset. Returns
   *              undefined if there's no expiration being enforced.
   */
  calculateExpirationTimestamp?: () => { isValid: boolean; expirationTimestamp: Date | undefined }
  /**
   * True if the token requires 2-step zapping or liquidations
   */
  isAsync: boolean
}

export interface SpecialAsset {
  /**
   * True to add this asset to the list returned in `dolomiteMarginTokenData.ts`
   */
  shouldMock?: boolean
  /**
   * The symbol of this special asset - this should match what's seen on Etherscan for the given asset.
   */
  symbol: SpecialAssetSymbol
  /**
   * The symbol as it can be used as a key in a map safely. For edge-cases like PT-GLP where the `-` is problematic for
   * GQL.
   */
  safeSymbol: string
  /**
   * The presentable symbol for this asset in the UI
   */
  cleanSymbol: string
  /**
   * The presentable name for this asset in the UI
   */
  cleanName: string
  /**
   * True if the asset receives yield outside of Dolomite, false if it doesn't
   */
  hasExternalRewards: boolean
  /**
   * A link to the Dolomite Gitbook explaining the special asset.
   */
  documentationUrl?: string
  /**
   * True if the object is wrapped in a Dolomite-compatible proxy vault (dubbed Isolation Mode).
   */
  isIsolationMode: boolean
  /**
   * True if the asset has borrowing enabled, false if it is not borrowable.
   */
  isBorrowingEnabled: boolean
  /**
   * Mapping from chainId to address
   */
  chainIdToAddressMap: ChainIdMap<string | undefined>
  /**
   * This object should be defined if `isIsolationMode` is true or undefined if `isIsolationMode` is false
   */
  isolationModeInfo?: IsolationModeInfo
  rewardInfo?: RewardInfo
}

export type RemapAddressFunction = (account: string | undefined, chainId: ChainId) => string | undefined

// Don't change these. That will break the app. They must match the asset name on chain exactly.
export enum SpecialAssetSymbol {
  dARB = 'dARB',
  dGMX = 'dGMX',
  dfsGLP = 'dfsGLP',
  dplvGLP = 'dplvGLP',
  djUSDC = 'djUSDC',
  dPtEzEthJune2024 = 'dPT-ezETH-27JUN2024',
  dPtEzEthSeptember2024 = 'dPT-ezETH-26SEP2024',
  dPtGLPMarch2024 = 'dPT-GLP-28MAR2024',
  dPtGLPSeptember2024 = 'dPT-GLP-26SEP2024',
  dPtMethDecember2024 = 'dPT-mETH-26DEC2024',
  dPtMntOctober2024 = 'dPT-sMNT-3OCT2024',
  dPtREthJune2025 = 'dPT-rETH-26JUN2025',
  dPtRsEthSeptember2024 = 'dPT-rsETH-26SEP2024',
  dPtUsdeDecember2024 = 'dPT-USDe-26DEC2024',
  dPtUsdeJuly2024 = 'dPT-USDe-25JUL2024',
  dPtWeEthApril2024 = 'dPT-weETH-25APR2024',
  dPtWeEthJune2024 = 'dPT-weETH-27JUN2024',
  dPtWeEthSeptember2024 = 'dPT-weETH-26SEP2024',
  dPtWstEthJun2024 = 'dPT-wstETH-27JUN2024',
  dPtWstEthJun2025 = 'dPT-wstETH-26JUN2025',
  dWMNT = 'dWMNT',
  dYtGLPMarch2024 = 'dYT-GLP-28MAR2024',
  ezETH = 'ezETH',
  gmAAVEUSD = 'gmAAVE-USD',
  gmARBUSD = 'gmARB-USD',
  gmBTC = 'gmBTC',
  gmBTCUSD = 'gmBTC-USD',
  gmDOGEUSD = 'gmDOGE-USD',
  gmETH = 'gmETH',
  gmETHUSD = 'gmETH-USD',
  gmGMXUSD = 'gmGMX-USD',
  gmLINKUSD = 'gmLINK-USD',
  gmSOLUSD = 'gmSOL-USD',
  gmUNIUSD = 'gmUNI-USD',
  gmWstETHUSD = 'gmWstETH-USD',
  GRAI = 'GRAI',
  magicGLP = 'mGLP',
  mETH = 'mETH',
  rETH = 'rETH',
  rsETH = 'rsETH',
  USDM = 'USDM',
  USDY = 'USDY',
  weETH = 'weETH',
  wstETH = 'wstETH',
  wUSDM = 'wUSDM',
  // For liquidity mining
  ARB = 'ARB',
  LINK = 'LINK',
  USDC = 'USDC',
  USDT = 'USDT',
  WBTC = 'WBTC',
  WETH = 'WETH',
  WMNT = 'WMNT',
  WOETH = 'WOETH',
}

/**
 * The main mapping that contributes to special assets being shown and used across the frontend.
 */
export const SPECIAL_ASSET_MAP: Record<string, SpecialAsset | undefined> = {
  [SpecialAssetSymbol.ARB]: ARB_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dfsGLP]: GLP_SPECIAL_ASSET(),
  [SpecialAssetSymbol.magicGLP]: MAGIC_GLP_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dARB]: D_ARB_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dGMX]: GMX_SPECIAL_ASSET(),
  [SpecialAssetSymbol.djUSDC]: J_USDC_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dplvGLP]: PLV_GLP_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtEzEthJune2024]: PT_EZ_ETH_JUN_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtEzEthSeptember2024]: PT_EZ_ETH_SEP_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtGLPMarch2024]: PT_GLP_MAR_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtGLPSeptember2024]: PT_GLP_SEP_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtMethDecember2024]: PT_METH_DEC_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtMntOctober2024]: PT_MNT_OCT_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtRsEthSeptember2024]: PT_RS_ETH_SEP_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtUsdeDecember2024]: PT_USDE_DEC_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtUsdeJuly2024]: PT_USDE_JUL_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtWeEthApril2024]: PT_WE_ETH_APR_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtWeEthJune2024]: PT_WE_ETH_JUN_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtWeEthSeptember2024]: PT_WE_ETH_SEP_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtREthJune2025]: PT_R_ETH_JUN_2025_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtWstEthJun2024]: PT_WST_ETH_JUN_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dPtWstEthJun2025]: PT_WST_ETH_JUN_2025_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dWMNT]: D_WMNT_SPECIAL_ASSET(),
  [SpecialAssetSymbol.dYtGLPMarch2024]: YT_GLP_MAR_2024_SPECIAL_ASSET(),
  [SpecialAssetSymbol.ezETH]: EZ_ETH_SPECIAL_ASSET(),
  [SpecialAssetSymbol.gmARBUSD]: GM_ARB_USD_SPECIAL_ASSET(),
  [SpecialAssetSymbol.gmAAVEUSD]: GM_AAVE_USD_SPECIAL_ASSET(),
  [SpecialAssetSymbol.gmBTC]: GM_BTC_SPECIAL_ASSET(),
  [SpecialAssetSymbol.gmBTCUSD]: GM_BTC_USD_SPECIAL_ASSET(),
  [SpecialAssetSymbol.gmDOGEUSD]: GM_DOGE_USD_SPECIAL_ASSET(),
  [SpecialAssetSymbol.gmETH]: GM_ETH_SPECIAL_ASSET(),
  [SpecialAssetSymbol.gmETHUSD]: GM_ETH_USD_SPECIAL_ASSET(),
  [SpecialAssetSymbol.gmGMXUSD]: GM_GMX_USD_SPECIAL_ASSET(),
  [SpecialAssetSymbol.gmLINKUSD]: GM_LINK_USD_SPECIAL_ASSET(),
  [SpecialAssetSymbol.gmSOLUSD]: GM_SOL_USD_SPECIAL_ASSET(),
  [SpecialAssetSymbol.gmUNIUSD]: GM_UNI_USD_SPECIAL_ASSET(),
  [SpecialAssetSymbol.gmWstETHUSD]: GM_WST_ETH_USD_SPECIAL_ASSET(),
  [SpecialAssetSymbol.GRAI]: GRAI_SPECIAL_ASSET(),
  [SpecialAssetSymbol.LINK]: LINK_SPECIAL_ASSET(),
  [SpecialAssetSymbol.mETH]: METH_SPECIAL_ASSET(),
  [SpecialAssetSymbol.rETH]: R_ETH_SPECIAL_ASSET(),
  [SpecialAssetSymbol.rsETH]: RS_ETH_SPECIAL_ASSET(),
  [SpecialAssetSymbol.USDC]: USDC_SPECIAL_ASSET(),
  [SpecialAssetSymbol.USDM]: USDM_SPECIAL_ASSET(),
  [SpecialAssetSymbol.USDT]: USDT_SPECIAL_ASSET(),
  [SpecialAssetSymbol.USDY]: USDY_SPECIAL_ASSET(),
  [SpecialAssetSymbol.weETH]: WE_ETH_SPECIAL_ASSET(),
  [SpecialAssetSymbol.wstETH]: WST_ETH_SPECIAL_ASSET(),
  [SpecialAssetSymbol.wUSDM]: W_USDM_SPECIAL_ASSET(),
  [SpecialAssetSymbol.WBTC]: WBTC_SPECIAL_ASSET(),
  [SpecialAssetSymbol.WETH]: WETH_SPECIAL_ASSET(),
  [SpecialAssetSymbol.WMNT]: WMNT_SPECIAL_ASSET(),
  [SpecialAssetSymbol.WOETH]: WOETH_SPECIAL_ASSET(),
}

const MOCKED_SPECIAL_ASSETS = Object.values(SPECIAL_ASSET_MAP).filter(
  (asset): asset is SpecialAsset => !!asset && !!asset.shouldMock,
)

Object.values(SPECIAL_ASSET_MAP).forEach(asset => {
  if (asset?.isIsolationMode) {
    invariant(
      !!asset.isolationModeInfo,
      `asset.isolationModeInfo must be defined if asset.isIsolationMode is true. Invalid asset: ${asset.symbol}}`,
    )
  } else if (asset && !asset.isIsolationMode) {
    invariant(
      !asset.isolationModeInfo,
      `asset.isolationModeInfo must be undefined if asset.isIsolationMode is false. Invalid asset: ${asset.symbol}}`,
    )
  }
})

/**
 * Used in `dolomiteMarginTokenData.ts` to filter out tokens from the interface that are not supported
 */
export const SPECIAL_TOKENS_TO_IGNORE_MAP: Record<string, boolean> = {
  [SpecialAssetSymbol.gmWstETHUSD]: true,
}

export const INACTIVE_TOKENS_MAP: ChainIdMap<Record<address, boolean>> = {
  [ChainId.MAINNET]: {},
  [ChainId.ARBITRUM_ONE]: {
    [toChecksumAddress(DPX_ADDRESSES[ChainId.ARBITRUM_ONE]!)]: true,
    [toChecksumAddress(PLV_GLP_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!)]: true,
    [toChecksumAddress(PENDLE_PT_GLP_MAR_2024_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!)]: true,
    [toChecksumAddress(PENDLE_YT_GLP_MAR_2024_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!)]: true,
  },
  [ChainId.BASE]: {},
  [ChainId.BERACHAIN]: {},
  [ChainId.MANTLE]: {},
  [ChainId.POLYGON_ZKEVM]: {},
  [ChainId.X_LAYER]: {},
}

export const TOKENS_TO_IGNORE_MAP: ChainIdMap<Record<address, boolean>> = {
  [ChainId.MAINNET]: {},
  [ChainId.ARBITRUM_ONE]: {
    [ModuleDeployments.JonesUSDCV1IsolationModeVaultFactory[ChainId.ARBITRUM_ONE].address]: true, // jUSDC V1
    [toChecksumAddress(S_GLP_ADDRESSES[ChainId.ARBITRUM_ONE]!)]: true, // sGLP
  },
  [ChainId.BASE]: {},
  [ChainId.BERACHAIN]: {},
  [ChainId.MANTLE]: {
    [toChecksumAddress('0x6B68bDCCb22931937d7D11e556d95a07E02fE3CE')]: true, // dWMNT
  },
  [ChainId.POLYGON_ZKEVM]: {
    [toChecksumAddress('0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035')]: true, // Old USDC
  },
  [ChainId.X_LAYER]: {},
}

export const GEOFENCED_TOKENS_MAP: ChainIdMap<Record<address, boolean>> = {
  [ChainId.MAINNET]: {},
  [ChainId.ARBITRUM_ONE]: {},
  [ChainId.BASE]: {},
  [ChainId.BERACHAIN]: {},
  [ChainId.MANTLE]: {
    [toChecksumAddress(USDY[ChainId.MANTLE]!.address!)]: true, // USDY
  },
  [ChainId.POLYGON_ZKEVM]: {},
  [ChainId.X_LAYER]: {},
}

export function useIsTokenGeoRestricted(address: string | undefined): boolean {
  const { chainId } = useActiveWeb3React()
  const { isUnitedStatesIp } = useIpGeolocation()
  return GEOFENCED_TOKENS_MAP[chainId][address ?? ''] && isUnitedStatesIp
}

export const TOKENS_TO_IGNORE_PRICES_MAP: ChainIdMap<Record<address, boolean>> = {
  [ChainId.MAINNET]: {},
  [ChainId.ARBITRUM_ONE]: {
    [ModuleDeployments.JonesUSDCV1IsolationModeVaultFactory[ChainId.ARBITRUM_ONE].address]: true, // jUSDC V1
  },
  [ChainId.BASE]: {},
  [ChainId.BERACHAIN]: {},
  [ChainId.MANTLE]: {},
  [ChainId.POLYGON_ZKEVM]: {},
  [ChainId.X_LAYER]: {},
}

export const SYMBOL_OVERRIDE_MAP: ChainIdMap<Record<string, string | undefined>> = {
  [ChainId.MAINNET]: {},
  [ChainId.ARBITRUM_ONE]: {
    [GM_AAVE_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_AAVE_USD_SPECIAL_ASSET().cleanSymbol,
    [GM_ARB_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_ARB_USD_SPECIAL_ASSET().cleanSymbol,
    [GM_BTC_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_BTC_SPECIAL_ASSET().cleanSymbol,
    [GM_BTC_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_BTC_USD_SPECIAL_ASSET().cleanSymbol,
    [GM_DOGE_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_DOGE_USD_SPECIAL_ASSET().cleanSymbol,
    [GM_ETH_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_ETH_SPECIAL_ASSET().cleanSymbol,
    [GM_ETH_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_ETH_USD_SPECIAL_ASSET().cleanSymbol,
    [GM_GMX_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_GMX_USD_SPECIAL_ASSET().cleanSymbol,
    [GM_LINK_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_LINK_USD_SPECIAL_ASSET().cleanSymbol,
    [GM_SOL_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_SOL_USD_SPECIAL_ASSET().cleanSymbol,
    [GM_UNI_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_UNI_USD_SPECIAL_ASSET().cleanSymbol,
    [GM_WST_ETH_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_WST_ETH_USD_SPECIAL_ASSET().cleanSymbol,
  },
  [ChainId.BASE]: {},
  [ChainId.BERACHAIN]: {},
  [ChainId.MANTLE]: {},
  [ChainId.POLYGON_ZKEVM]: {
    ['0x37eAA0eF3549a5Bb7D431be78a3D99BD360d19e5']: 'USDC.E',
  },
  [ChainId.X_LAYER]: {},
}

export const NAME_OVERRIDE_MAP: ChainIdMap<Record<string, string | undefined>> = {
  [ChainId.MAINNET]: {},
  [ChainId.ARBITRUM_ONE]: {
    [GM_AAVE_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_AAVE_USD_SPECIAL_ASSET().cleanName,
    [GM_ARB_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_ARB_USD_SPECIAL_ASSET().cleanName,
    [GM_BTC_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_BTC_SPECIAL_ASSET().cleanName,
    [GM_BTC_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_BTC_USD_SPECIAL_ASSET().cleanName,
    [GM_DOGE_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_DOGE_USD_SPECIAL_ASSET().cleanName,
    [GM_ETH_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_ETH_SPECIAL_ASSET().cleanName,
    [GM_ETH_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_ETH_USD_SPECIAL_ASSET().cleanName,
    [GM_GMX_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_GMX_USD_SPECIAL_ASSET().cleanName,
    [GM_LINK_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_LINK_USD_SPECIAL_ASSET().cleanName,
    [GM_SOL_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_SOL_USD_SPECIAL_ASSET().cleanName,
    [GM_UNI_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_UNI_USD_SPECIAL_ASSET().cleanName,
    [GM_WST_ETH_USD_ISOLATION_MODE_ADDRESSES[ChainId.ARBITRUM_ONE]!]: GM_WST_ETH_USD_SPECIAL_ASSET().cleanName,
  },
  [ChainId.BASE]: {},
  [ChainId.BERACHAIN]: {},
  [ChainId.MANTLE]: {},
  [ChainId.POLYGON_ZKEVM]: {},
  [ChainId.X_LAYER]: {},
}

export function isTokenIgnored(
  chainId: ChainId,
  address: string | undefined,
  symbol: string | undefined,
  showInactiveTokens: boolean,
) {
  const inactive = !showInactiveTokens && INACTIVE_TOKENS_MAP[chainId][address ?? '']
  return inactive || SPECIAL_TOKENS_TO_IGNORE_MAP[symbol ?? ''] || TOKENS_TO_IGNORE_MAP[chainId][address ?? '']
}

export function useSpecialAsset(token?: Token): SpecialAsset | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => getSpecialAsset(chainId, token), [chainId, token])
}

export function getSpecialAsset(chainId: ChainId, token: Token | undefined): SpecialAsset | undefined {
  const specialAsset = SPECIAL_ASSET_MAP[token?.symbol ?? '']
  if (specialAsset?.chainIdToAddressMap[chainId]) {
    return specialAsset
  } else {
    return undefined
  }
}

export function getIsolationModeTokenBySymbolAndChainId(
  chainId: ChainId,
  symbol: SpecialAssetSymbol,
): Token | undefined {
  return SPECIAL_ASSET_MAP[symbol]!.isolationModeInfo!.getWrappedToken(chainId)
}

export function useAllSpecialAssets(): SpecialAsset[] {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => getAllSpecialAssets(chainId), [chainId])
}

export function getAllSpecialAssets(chainId: ChainId): SpecialAsset[] {
  return Object.values(SPECIAL_ASSET_MAP).filter(
    (asset): asset is SpecialAsset => !!asset && !!asset.chainIdToAddressMap[chainId],
  )
}

export function getMockedSpecialAssets(): SpecialAsset[] {
  return MOCKED_SPECIAL_ASSETS
}

export function useDARBSpecialAsset(): SpecialAsset {
  return useMemo(() => SPECIAL_ASSET_MAP[SpecialAssetSymbol.dARB]!, [])
}

export function useDfsGLPSpecialAsset(): SpecialAsset {
  return useMemo(() => SPECIAL_ASSET_MAP[SpecialAssetSymbol.dfsGLP]!, [])
}

export function useDGMXSpecialAsset(): SpecialAsset {
  return useMemo(() => SPECIAL_ASSET_MAP[SpecialAssetSymbol.dGMX]!, [])
}

export function useDjUSDCSpecialAsset(): SpecialAsset {
  return useMemo(() => SPECIAL_ASSET_MAP[SpecialAssetSymbol.djUSDC]!, [])
}

export function useDWmntSpecialAsset(): SpecialAsset {
  return useMemo(() => SPECIAL_ASSET_MAP[SpecialAssetSymbol.dWMNT]!, [])
}

export function useDplvGLPSpecialAsset(): SpecialAsset {
  return useMemo(() => SPECIAL_ASSET_MAP[SpecialAssetSymbol.dplvGLP]!, [])
}

export function useDytGLPSpecialAsset(): SpecialAsset {
  return useMemo(() => SPECIAL_ASSET_MAP[SpecialAssetSymbol.dYtGLPMarch2024]!, [])
}
