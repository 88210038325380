import { ChainId, initializeSingleValueChainIdMap } from '../chainId'

export const ARB_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x5023da68d95eed40238a37623c9ad7c7060cd372946227b3bcaa6f1ac8ac7e82',
)

export const GLP_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x327d35905d977685b2f8a96ec2c97f850f0dcee89ccd3330d6a29b0c03a56a90',
)

export const GM_AAVE_USD_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x11b6f50e652c2a27b14944bf24354e8899668ff8ac571e58bced32e794f9c61d',
)

export const GM_ARB_USD_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xe3ad80f5509f151c0e888fa7cdc5b5a5e15108a070ad524cf9dc07186df184de',
)

export const GM_BTC_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x36586cb60d05c6e079fd75eaa1a8e387d00a13b04a77b116d5645d9e0c715a5c',
)

export const GM_BTC_USD_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xe3ad80f5509f151c0e888fa7cdc5b5a5e15108a070ad524cf9dc07186df184de',
)

export const GM_DOGE_USD_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x11b6f50e652c2a27b14944bf24354e8899668ff8ac571e58bced32e794f9c61d',
)

export const GM_ETH_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x36586cb60d05c6e079fd75eaa1a8e387d00a13b04a77b116d5645d9e0c715a5c',
)

export const GM_ETH_USD_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xe3ad80f5509f151c0e888fa7cdc5b5a5e15108a070ad524cf9dc07186df184de',
)

export const GM_GMX_USD_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x11b6f50e652c2a27b14944bf24354e8899668ff8ac571e58bced32e794f9c61d',
)

export const GM_LINK_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xe3ad80f5509f151c0e888fa7cdc5b5a5e15108a070ad524cf9dc07186df184de',
)

export const GM_SOL_USD_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x11b6f50e652c2a27b14944bf24354e8899668ff8ac571e58bced32e794f9c61d',
)

export const GM_UNI_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xf6a4302c77c6e44341d58522ef07d39ea7ea5de20c9a60d968b5e1801ee4635a',
)

export const GM_WST_ETH_USD_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x11b6f50e652c2a27b14944bf24354e8899668ff8ac571e58bced32e794f9c61d',
)

export const GMX_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x5023da68d95eed40238a37623c9ad7c7060cd372946227b3bcaa6f1ac8ac7e82',
)

export const JONES_USDC_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x962399922755c295e541a198151d7c26acb4e7068a2ed07fdc972ed51a646b30',
)

export const PENDLE_PT_EZ_ETH_JUN_2024_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xbf8544f95ac5644fb23ad137ace98fba4a1b3d89ed415a06c64b420ed2295f69',
)

export const PENDLE_PT_EZ_ETH_SEP_2024_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x9784b2028a90c4f2162d832616b0cfc81588f9e58f1e682f4684e85c5da89ed2',
)

export const PENDLE_PT_GLP_MAR_2024_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x0032811774d76fef65c931bbc8a50665b750d14e62b31f348dc3fbc9566fc5b6',
)

export const PENDLE_PT_GLP_SEP_2024_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x962399922755c295e541a198151d7c26acb4e7068a2ed07fdc972ed51a646b30',
)

export const PENDLE_PT_METH_DEC_2024_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.MANTLE,
  '0x3611bc0464dd3375d59c3d1dd6b0e6c83a08f9073e47ee48fd927678ac7c5617',
)

export const PENDLE_PT_MNT_OCT_2024_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.MANTLE,
  '0x11b6f50e652c2a27b14944bf24354e8899668ff8ac571e58bced32e794f9c61d',
)

export const PENDLE_PT_R_ETH_JUN_2025_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x45a0a27b5c9ea7fa1a3da214e9e743189b28e3d054fb17e0d6917332caf7bf13',
)

export const PENDLE_PT_RS_ETH_SEP_2024_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x9784b2028a90c4f2162d832616b0cfc81588f9e58f1e682f4684e85c5da89ed2',
)

export const PENDLE_PT_USDE_DEC_2024_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.MANTLE,
  '0x581e7c47f61da69d8cd2aa08172aca5d7265ce4f8831c4a4106164558a439d08',
)

export const PENDLE_PT_USDE_JUL_2024_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.MANTLE,
  '0x9784b2028a90c4f2162d832616b0cfc81588f9e58f1e682f4684e85c5da89ed2',
)

export const PENDLE_PT_WE_ETH_APR_2024_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x89c20ad4d35b67428d3b7f42e85adbdd1fc5df45c3daeb06ae22a8f3ad63c2e4',
)

export const PENDLE_PT_WE_ETH_JUN_2024_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x962399922755c295e541a198151d7c26acb4e7068a2ed07fdc972ed51a646b30',
)

export const PENDLE_PT_WE_ETH_SEP_2024_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x9784b2028a90c4f2162d832616b0cfc81588f9e58f1e682f4684e85c5da89ed2',
)

export const PENDLE_PT_WST_ETH_JUN_2024_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x45a0a27b5c9ea7fa1a3da214e9e743189b28e3d054fb17e0d6917332caf7bf13',
)

export const PENDLE_PT_WST_ETH_JUN_2025_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x45a0a27b5c9ea7fa1a3da214e9e743189b28e3d054fb17e0d6917332caf7bf13',
)

export const PENDLE_YT_GLP_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x5ceed0e9b34f60cddf1237e1d12197fc9f2771a45ebe7951c5e06ef8140d29f1',
)

export const PLV_GLP_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x6d09cd08a303e0ccd4c24b81dd41322f7399e5c19360ef9f819d2f48fd1b64ca',
)

export const WMNT_PROXY_VAULT_INIT_HASHES = initializeSingleValueChainIdMap(
  ChainId.MANTLE,
  '0x3611bc0464dd3375d59c3d1dd6b0e6c83a08f9073e47ee48fd927678ac7c5617',
)
