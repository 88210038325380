import ANTLogo from '../../assets/markets/ANT.png'
import ARBLogo from '../../assets/markets/ARB.svg'
import BATLogo from '../../assets/markets/BAT.png'
import BERALogo from '../../assets/markets/BERA.svg'
import BNTLogo from '../../assets/markets/BNT.png'
import DAILogo from '../../assets/markets/DAI.png'
import DPXLogo from '../../assets/markets/DPX.svg'
import ETHLogo from '../../assets/markets/ETH.svg'
import EzETHLogo from '../../assets/markets/ezETH.svg'
import FBTCLogo from '../../assets/markets/FBTC.svg'
import GLPLogo from '../../assets/markets/GLP.png'
import GmAAVELogo from '../../assets/markets/gmAAVE.svg'
import GmARBLogo from '../../assets/markets/ARB-GM.svg'
import GmBTCLogo from '../../assets/markets/WBTC-GM.svg'
import GmDOGELogo from '../../assets/markets/gmDOGE.svg'
import GmETHLogo from '../../assets/markets/ETH-GM.svg'
import GmGMXLogo from '../../assets/markets/gmGMX.svg'
import GmLINKLogo from '../../assets/markets/LINK-GM.svg'
import GmSOLLogo from '../../assets/markets/gmSOL.svg'
import GmUNILogo from '../../assets/markets/UNI-GM.svg'
import GmWstETHLogo from '../../assets/markets/gmWstETH.svg'
import GMXLogo from '../../assets/markets/GMX.svg'
import GRAILogo from '../../assets/markets/GRAI.svg'
import GRAILLogo from '../../assets/markets/GRAIL.svg'
import HONEYLogo from '../../assets/markets/HONEY.svg'
import JONESLogo from '../../assets/markets/JONES.svg'
import jUSDCLogo from '../../assets/markets/jUSDC.svg'
import KNCLogo from '../../assets/markets/KNC.png'
import LINKLogo from '../../assets/markets/LINK.png'
import LRCLogo from '../../assets/markets/LRC.png'
import MAGICLogo from '../../assets/markets/MAGIC.svg'
import MATICLogo from '../../assets/markets/MATIC.svg'
import MagicGLPLogo from '../../assets/markets/mGLP-dual-logo.svg'
import METHLogo from '../../assets/markets/mETH.svg'
import MIMLogo from '../../assets/markets/MIM.svg'
import MNTLogo from '../../assets/markets/MNT.svg'
import OKBLogo from '../../assets/markets/OKB.svg'
import PENDLELogo from '../../assets/markets/PENDLE.svg'
import plvGLP from '../../assets/markets/plvGLP.svg'
import PREMIALogo from '../../assets/markets/PREMIA.svg'
import PTezETHLogo from '../../assets/markets/PT-ezETH.svg'
import PTGLPLogo from '../../assets/markets/PT-GLP.svg'
import PTmETHLogo from '../../assets/markets/PT-mETH.svg'
import PTMNTLogo from '../../assets/markets/PT-MNT.svg'
import PTrETHLogo from '../../assets/markets/PT-rETH.svg'
import PTrsETHLogo from '../../assets/markets/PT-rsETH.svg'
import PTUSDeLogo from '../../assets/markets/PT-USDe.svg'
import PTweETHLogo from '../../assets/markets/PT-weETH.svg'
import PTwstETHLogo from '../../assets/markets/PT-wstETH.svg'
import PTSILogo from '../../assets/markets/PTSI.svg'
import RDNTLogo from '../../assets/markets/RDNT.svg'
import REPLogo from '../../assets/markets/REP.png'
import rETHLogo from '../../assets/markets/rETH.svg'
import rsETHLogo from '../../assets/markets/rsETH.svg'
import sMNTLogo from '../../assets/markets/sMNT.svg'
import UNILogo from '../../assets/markets/UNI.svg'
import USDCLogo from '../../assets/markets/USDC.svg'
import USDeLogo from '../../assets/markets/USDe-alt.svg'
import USDMLogo from '../../assets/markets/USDM.svg'
import USDTLogo from '../../assets/markets/USDT.png'
import USDYLogo from '../../assets/markets/USDY.svg'
import WBERALogo from '../../assets/markets/wBERA.svg'
import WBTCLogo from '../../assets/markets/WBTC.png'
import WETHLogo from '../../assets/markets/WETH.png'
import weETHLogo from '../../assets/markets/weETH.svg'
import WMATICLogo from '../../assets/markets/WMATIC.png'
import WMNTLogo from '../../assets/markets/WMNT.svg'
import wOETHLogo from '../../assets/markets/WOETH.svg'
import WOKBLogo from '../../assets/markets/WOKB.svg'
import wstETHLogo from '../../assets/markets/wstETH.svg'
import wUSDMLogo from '../../assets/markets/wUSDM.svg'
import XAILogo from '../../assets/markets/XAI.svg'
import ZRXLogo from '../../assets/markets/ZRX.png'

import UNKNOKWNLogo from '../../assets/images/question.svg'

type TokenLogo = Record<string, string>

const logos: TokenLogo = {
  ANT: ANTLogo,
  ARB: ARBLogo,
  BAT: BATLogo,
  BERA: BERALogo,
  BNT: BNTLogo,
  DAI: DAILogo,
  DPX: DPXLogo,
  ETH: ETHLogo,
  ezETH: EzETHLogo,
  FBTC: FBTCLogo,
  GLP: GLPLogo,
  'gmAAVE-USD': GmAAVELogo,
  'gmARB-USD': GmARBLogo,
  gmBTC: GmBTCLogo,
  'gmBTC-USD': GmBTCLogo,
  'gmDOGE-USD': GmDOGELogo,
  gmETH: GmETHLogo,
  'gmETH-USD': GmETHLogo,
  'gmGMX-USD': GmGMXLogo,
  'gmLINK-USD': GmLINKLogo,
  'gmSOL-USD': GmSOLLogo,
  'gmUNI-USD': GmUNILogo,
  'gmWstETH-USD': GmWstETHLogo,
  GMX: GMXLogo,
  GRAI: GRAILogo,
  GRAIL: GRAILLogo,
  HONEY: HONEYLogo,
  JONES: JONESLogo,
  jUSDC: jUSDCLogo,
  KNC: KNCLogo,
  LINK: LINKLogo,
  LRC: LRCLogo,
  MAGIC: MAGICLogo,
  MATIC: MATICLogo,
  mETH: METHLogo,
  mGLP: MagicGLPLogo,
  MIM: MIMLogo,
  MNT: MNTLogo,
  OKB: OKBLogo,
  PENDLE: PENDLELogo,
  plvGLP: plvGLP,
  PREMIA: PREMIALogo,
  'PT-eETH': PTweETHLogo,
  'PT-ezETH': PTezETHLogo,
  'PT-GLP': PTGLPLogo,
  'PT-mETH': PTmETHLogo,
  'PT-MNT': PTMNTLogo,
  'PT-rETH': PTrETHLogo,
  'PT-rsETH': PTrsETHLogo,
  'PT-USDe': PTUSDeLogo,
  'PT-wstETH': PTwstETHLogo,
  PTSI: PTSILogo,
  RDNT: RDNTLogo,
  REP: REPLogo,
  rETH: rETHLogo,
  rsETH: rsETHLogo,
  sGMX: GMXLogo,
  sMNT: sMNTLogo,
  UNKNOWN: UNKNOKWNLogo,
  UNI: UNILogo,
  USDC: USDCLogo,
  'USDC.E': USDCLogo,
  'USDC.e': USDCLogo,
  USDe: USDeLogo,
  USDM: USDMLogo,
  USDT: USDTLogo,
  USDY: USDYLogo,
  vARB: ARBLogo,
  weETH: weETHLogo,
  WBERA: WBERALogo,
  WBTC: WBTCLogo,
  WETH: WETHLogo,
  WETH9: WETHLogo,
  WMATIC: WMATICLogo,
  WMNT: WMNTLogo,
  wOETH: wOETHLogo,
  WOKB: WOKBLogo,
  wstETH: wstETHLogo,
  wUSDM: wUSDMLogo,
  'YT-GLP': PTGLPLogo,
  XAI: XAILogo,
  ZRX: ZRXLogo,
}

export default function getLogoOrDefault(symbol: string) {
  return logos[symbol] || logos.UNKNOWN
}
