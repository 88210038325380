import { ChainId, GM_UNI_USD_ADDRESSES, GM_UNI_USD_ISOLATION_MODE_ADDRESSES } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculateGmUNIUSDProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getOptionally } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  getGmCollateralTokens,
  getGmDebtTokens,
  getGmUsdValidInputTokens,
  getGmUsdValidOutputTokens,
} from './special-gm-tokens'
import { UNI } from '../../tokens/UNI'

const cleanSymbol = 'gmUNI-USD'
const cleanName = 'GM: UNI-USD'

export const GM_UNI_USD_SPECIAL_ASSET: () => SpecialAsset = () => ({
  cleanSymbol,
  cleanName,
  symbol: SpecialAssetSymbol.gmUNIUSD,
  safeSymbol: SpecialAssetSymbol.gmUNIUSD,
  hasExternalRewards: false,
  documentationUrl: 'https://docs.dolomite.io/integrations/gmx/gm',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: GM_UNI_USD_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        GM_UNI_USD_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, cleanSymbol, `Dolomite Isolation: ${cleanName}`),
      ),
    getUnderlyingToken: getGmToken,
    getUnderlyingSpenderToken: getGmToken,
    allowableCollateralTokens: (chainId: ChainId) =>
      getGmCollateralTokens(SpecialAssetSymbol.gmUNIUSD, chainId, UNI[chainId]),
    allowableDebtTokens: (chainId: ChainId) => getGmDebtTokens(chainId, UNI[chainId]),
    allowableInputTokensForZap: (chainId: ChainId) => getGmUsdValidInputTokens(chainId, UNI[chainId]),
    allowableOutputTokensForZap: (chainId: ChainId) => getGmUsdValidOutputTokens(chainId, UNI[chainId]),
    remapAccountAddress: calculateGmUNIUSDProxyVaultAddress,
    isAsync: true,
  },
})

function getGmToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    GM_UNI_USD_ADDRESSES[chainId],
    address => new Token(chainId, address, 18, cleanSymbol, cleanSymbol),
  )
}
