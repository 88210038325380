import { ChainId, GM_BTC_ADDRESSES, GM_BTC_ISOLATION_MODE_ADDRESSES, WBTC } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculateGmBTCProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getOptionally } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  getGmCollateralTokens,
  getGmDebtTokens,
  getGmValidInputTokens,
  getGmValidOutputTokens,
} from './special-gm-tokens'

const cleanSymbol = 'gmBTC'
const cleanName = 'GM: BTC'

export const GM_BTC_SPECIAL_ASSET: () => SpecialAsset = () => ({
  cleanSymbol,
  cleanName,
  symbol: SpecialAssetSymbol.gmBTC,
  safeSymbol: SpecialAssetSymbol.gmBTC,
  hasExternalRewards: false,
  documentationUrl: 'https://docs.dolomite.io/integrations/gmx/gm',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: GM_BTC_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        GM_BTC_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, cleanSymbol, `Dolomite Isolation: ${cleanName}`),
      ),
    getUnderlyingToken: getGmToken,
    getUnderlyingSpenderToken: getGmToken,
    allowableCollateralTokens: (chainId: ChainId) =>
      getGmCollateralTokens(SpecialAssetSymbol.gmBTC, chainId, WBTC[chainId]),
    allowableDebtTokens: (chainId: ChainId) => getGmDebtTokens(chainId, WBTC[chainId]),
    allowableInputTokensForZap: (chainId: ChainId) => getGmValidInputTokens(WBTC[chainId]),
    allowableOutputTokensForZap: (chainId: ChainId) => getGmValidOutputTokens(WBTC[chainId]),
    remapAccountAddress: calculateGmBTCProxyVaultAddress,
    isAsync: true,
  },
})

function getGmToken(chainId: ChainId): Token | undefined {
  return getOptionally(GM_BTC_ADDRESSES[chainId], address => new Token(chainId, address, 18, cleanSymbol, cleanSymbol))
}
