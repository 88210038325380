import { Token } from '@dolomite-exchange/v2-sdk'
import { SPECIAL_ASSET_MAP, SpecialAssetSymbol } from '../special-assets'
import { BRIDGED_USDC, USDC } from '../../tokens/USDC'
import { DAI } from '../../tokens/DAI'
import { USDT } from '../../tokens/USDT'
import { MIM } from '../../tokens/MIM'
import { ChainId } from '../../chainId'
import { GRAI } from '../../tokens/GRAI'
import { W_USDM } from '../../tokens/W_USDM'
import { USDE } from '../../tokens/USDE'

export function getGmCollateralTokens(
  symbol: SpecialAssetSymbol,
  chainId: ChainId,
  indexToken: Token | undefined,
): Token[] {
  const tokens = getGmDebtTokens(chainId, indexToken)
  if (tokens.length === 0 || !indexToken) {
    return []
  }
  return [...tokens, SPECIAL_ASSET_MAP[symbol]!.isolationModeInfo!.getWrappedToken(indexToken.chainId)!]
}

export function getGmDebtTokens(chainId: ChainId, indexToken: Token | undefined): Token[] {
  if (chainId !== ChainId.ARBITRUM_ONE) {
    return []
  }
  return [
    USDC[chainId],
    BRIDGED_USDC[chainId]!,
    DAI[chainId]!,
    GRAI[chainId]!,
    USDT[chainId]!,
    USDE[chainId]!,
    W_USDM[chainId]!,
    MIM[chainId]!,
    ...(indexToken ? [indexToken] : []),
  ]
}

export function getGmUsdValidInputTokens(chainId: ChainId, longToken: Token | undefined, shortToken?: Token): Token[] {
  if (!longToken) {
    return [shortToken ? shortToken : USDC[chainId]]
  }
  return [shortToken ? shortToken : USDC[chainId], longToken]
}

export function getGmUsdValidOutputTokens(chainId: ChainId, longToken: Token | undefined, shortToken?: Token): Token[] {
  if (!longToken) {
    return [shortToken ? shortToken : USDC[chainId]]
  }
  return [shortToken ? shortToken : USDC[chainId], longToken]
}

export function getGmValidInputTokens(longToken: Token | undefined): Token[] {
  if (!longToken || longToken.chainId !== ChainId.ARBITRUM_ONE) {
    return []
  }
  return [longToken]
}

export function getGmValidOutputTokens(longToken: Token | undefined): Token[] {
  if (!longToken || longToken.chainId !== ChainId.ARBITRUM_ONE) {
    return []
  }
  return [longToken]
}
