import { Fraction, Percent, Token, WRAPPED_CURRENCY } from '@dolomite-exchange/sdk-core'
import JSBI from 'jsbi'
import {
  ChainId,
  ChainIdMap,
  initializeObjectChainIdMap,
  initializeObjectUndefinedChainIdMap,
  initializeSingleValueChainIdMap,
  initializeStringOptChainIdMap,
} from './chainId'
import { ARB } from './tokens/ARB'
import { DAI } from './tokens/DAI'
import { LINK } from './tokens/LINK'
import { OARB } from './tokens/OARB'
import { UNI } from './tokens/UNI'
import { BRIDGED_USDC, USDC } from './tokens/USDC'
import { USDT } from './tokens/USDT'
import { USDY } from './tokens/USDY'
import { W_USDM } from './tokens/W_USDM'
import { WBTC } from './tokens/WBTC'
import { WETH } from './tokens/WETH'
import { WMNT } from './tokens/WMNT'
import { ES_GMX } from './tokens/ES_GMX'
import { MaxUint256 } from '@dolomite-exchange/v2-sdk'
import CoreDeployments from '@dolomite-exchange/dolomite-margin/dist/migrations/deployed.json'
import Deployments from '@dolomite-exchange/dolomite-margin/dist/migrations/deployed.json'
import ModuleDeployments from '@dolomite-exchange/modules-deployments/src/deploy/deployments.json'
import { MIM } from './tokens/MIM'
import { GRAI } from './tokens/GRAI'
import { WST_ETH } from './tokens/WST_ETH'
import { WE_ETH } from './tokens/WE_ETH'
import { WO_ETH } from './tokens/WO_ETH'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export { PRELOADED_PROPOSALS } from './proposals'

export const FORMATTER = {
  groupSeparator: ',',
}

export { ChainId }

// a list of tokens by chain
export type ChainTokenList = ChainIdMap<Token[] | undefined>
export { ARB }
export { DAI }
export { ES_GMX }
export { LINK }
export { OARB }
export { UNI }
export { USDC }
export { USDT }
export { USDY }
export { W_USDM }
export { WBTC }
export { WE_ETH }
export { WETH }
export { WMNT }
export { WO_ETH }
export { WST_ETH }

export const USER_ERROR_CODES: Record<string, number | undefined> = {
  REJECTED: 4001,
}

export const USER_ERROR_MESSAGES = {
  REJECTED: 'Transaction cancelled',
  INSUFFICIENT_GAS_TOKEN: 'Insufficient gas token in your wallet',
}

export const GOVERNANCE_ADDRESS: Record<string, string> = {
  [ChainId.MAINNET]: '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F',
}

export const ALWAYS_ZERO_INTEREST_SETTER_ADDRESSES = initializeStringOptChainIdMap(
  CoreDeployments.AlwaysZeroInterestSetter,
)
export const ARB_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.ARBIsolationModeVaultFactory,
)

export const BN_GMX_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x35247165119B69A40edD5304969560D0ef486921',
)

export const BN_GMX_REWARD_TRACKER_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x4d268a7d4C16ceB5a606c173Bd974984343fea13',
)

export const BORROW_POSITION_PROXY_V1_ADDRESSES = initializeStringOptChainIdMap(CoreDeployments.BorrowPositionProxyV1)

export const DEPOSIT_WITHDRAWAL_PROXY_ADDRESSES = initializeStringOptChainIdMap(CoreDeployments.DepositWithdrawalProxy)

export const DOLOMITE_AMM_ROUTER_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xD8F9C59176AE25414FC4180f6433Fc45b0cbb632',
)

export const DOLOMITE_MARGIN_PROTOCOL_ADDRESSES = initializeStringOptChainIdMap(CoreDeployments.DolomiteMargin)

export const DOUBLE_EXPONENT_INTEREST_SETTER_ADDRESSES = initializeStringOptChainIdMap(
  CoreDeployments.DoubleExponentInterestSetter,
)

export const DPX_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x6C2C06790b3E3E3c38e12Ee22F8183b37a13EE55',
)

export const ES_GMX_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA',
)

export const EXPIRY_TRADER_ADDRESSES = initializeStringOptChainIdMap(CoreDeployments.Expiry)

export const EZ_ETH_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x2416092f143378750bb29b79eD961ab195CcEea5',
)

export const FEE_GLP_REWARD_TRACKER_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x4e971a87900b931fF39d1Aad67697F49835400b6',
)

/**
 * The sbfGMX token address
 */
export const FEE_GMX_REWARD_TRACKER_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xd2D1162512F927a7e282Ef43a362659E4F2a728F',
)

export const FS_GLP_ISOLATION_MODE_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x34DF4E8062A8C8Ae97E3382B452bd7BF60542698',
)

export const GENERIC_TRADER_PROXY_V1_ADDRESSES = initializeStringOptChainIdMap(CoreDeployments.GenericTraderProxyV1)

export const GLP_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258',
)

export const GLP_MANAGER_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x3963FfC9dff443c2A94f21b129D429891E32ec18',
)

export const GLP_VESTER_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xA75287d2f8b217273E7FCD7E86eF07D33972042E',
)

export const GM_AAVE_USD_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x1CbBa6346F110c8A5ea739ef2d1eb182990e4EB2',
)

export const GM_AAVE_USD_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.GmxV2AAVEIsolationModeVaultFactory,
)

export const GM_ARB_USD_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xC25cEf6061Cf5dE5eb761b50E4743c1F5D7E5407',
)

export const GM_ARB_USD_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.GmxV2ARBIsolationModeVaultFactory,
)

export const GM_BTC_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x7C11F78Ce78768518D743E81Fdfa2F860C6b9A77',
)

export const GM_BTC_USD_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x47c031236e19d024b42f8AE6780E44A573170703',
)

export const GM_BTC_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.GmxV2SingleSidedBTCIsolationModeVaultFactory,
)

export const GM_BTC_USD_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.GmxV2BTCIsolationModeVaultFactory,
)

export const GM_DOGE_USD_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x6853EA96FF216fAb11D2d930CE3C508556A4bdc4',
)

export const GM_DOGE_USD_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.GmxV2DOGEIsolationModeVaultFactory,
)

export const GM_ETH_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x450bb6774Dd8a756274E0ab4107953259d2ac541',
)

export const GM_ETH_USD_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x70d95587d40A2caf56bd97485aB3Eec10Bee6336',
)

export const GM_ETH_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.GmxV2SingleSidedETHIsolationModeVaultFactory,
)

export const GM_ETH_USD_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.GmxV2ETHIsolationModeVaultFactory,
)

export const GM_GMX_USD_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x55391D178Ce46e7AC8eaAEa50A72D1A5a8A622Da',
)

export const GM_GMX_USD_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.GmxV2GMXIsolationModeVaultFactory,
)

export const GM_LINK_USD_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x7f1fa204bb700853D36994DA19F830b6Ad18455C',
)

export const GM_LINK_USD_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.GmxV2LINKIsolationModeVaultFactory,
)

export const GM_SOL_USD_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x09400D9DB990D5ed3f35D7be61DfAEB900Af03C9',
)

export const GM_SOL_USD_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.GmxV2SOLIsolationModeVaultFactory,
)

export const GM_UNI_USD_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xc7Abb2C5f3BF3CEB389dF0Eecd6120D451170B50',
)

export const GM_UNI_USD_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.GmxV2UNIIsolationModeVaultFactory,
)

export const GM_WST_ETH_USD_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x0Cf1fb4d1FF67A3D8Ca92c9d6643F8F9be8e03E5',
)

export const GM_WST_ETH_USD_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.GmxV2WstETHIsolationModeVaultFactory,
)

export const GMX_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
)

export const GMX_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.GMXIsolationModeVaultFactory,
)

export const GMX_READER_V2_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694',
)

export const GMX_REWARD_READER_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0',
)

export const GMX_REWARD_ROUTER_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x159854e14A862Df9E39E1D128b8e5F70B4A3cE9B',
)

export const GMX_VESTER_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x199070DDfd1CFb69173aa2F7e20906F26B363004',
)

export const JONES_USDC_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xB0BDE111812EAC913b392D80D51966eC977bE3A2',
)

export const JONES_USDC_FARM_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x0aEfaD19aA454bCc1B1Dd86e18A7d58D0a6FAC38',
)

export const JONES_USDC_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.JonesUSDCV2IsolationModeVaultFactory,
)

export const LIQUIDITY_MINING_MINERAL_CLAIM_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.MineralDistributor,
)

export const LIQUIDITY_MINING_O_TOKEN_CLAIM_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.OARBRewardsDistributor,
)

export const LIQUIDITY_MINING_O_TOKEN_VESTER_ADDRESSES = initializeStringOptChainIdMap(ModuleDeployments.VesterProxy)

export const MAGIC_GLP_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x85667409a723684Fe1e57Dd1ABDe8D88C2f54214',
)

export const MERKLE_DISTRIBUTOR_ADDRESS = initializeObjectUndefinedChainIdMap()

export const MIN_ADDRESSES = initializeStringOptChainIdMap(ModuleDeployments.MineralTokenProxy)

export const MULTICALL_ADDRESSES: ChainIdMap<string> = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ARBITRUM_ONE]: Deployments.ArbitrumMultiCall[ChainId.ARBITRUM_ONE].address,
  [ChainId.BASE]: Deployments.MultiCall[ChainId.BASE].address,
  [ChainId.BERACHAIN]: Deployments.MultiCall[ChainId.BERACHAIN].address,
  [ChainId.MANTLE]: Deployments.MultiCall[ChainId.MANTLE].address,
  [ChainId.POLYGON_ZKEVM]: Deployments.MultiCall[ChainId.POLYGON_ZKEVM].address,
  [ChainId.X_LAYER]: Deployments.MultiCall[ChainId.X_LAYER].address,
}

export const PENDLE_PT_EZ_ETH_JUN_2024_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x8EA5040d423410f1fdc363379Af88e1DB5eA1C34',
)

export const PENDLE_PT_EZ_ETH_SEP_2024_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x2ccfce9be49465cc6f947b5f6ac9383673733da9',
)

export const PENDLE_PT_GLP_MAR_2024_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x96015D0Fb97139567a9ba675951816a0Bb719E3c',
)

export const PENDLE_PT_GLP_SEP_2024_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x89cD713a6607787F93d6743E67777Be9Ad73c54b',
)

export const PENDLE_PT_METH_DEC_2024_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.MANTLE,
  '0x1333b49bbdd06544a25647f9127358d9a9486105',
)

export const PENDLE_PT_MNT_OCT_2024_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.MANTLE,
  '0xc57c7be308cf2f52dcf095d8d4c67d5984270da0',
)

export const PENDLE_PT_R_ETH_JUN_2025_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x685155D3BD593508Fe32Be39729810A591ED9c87',
)

export const PENDLE_PT_RS_ETH_SEP_2024_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x30c98c0139b62290e26ac2a2158ac341dcaf1333',
)

export const PENDLE_PT_USDE_DEC_2024_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.MANTLE,
  '0x8be66a48ea1f4aff89cd2beb50b02d901dfb9584',
)

export const PENDLE_PT_USDE_JUL_2024_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.MANTLE,
  '0xba567cf0d8230c0ad8d8bfc50e587e06d6f118e9',
)

export const PENDLE_PT_WE_ETH_APR_2024_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x9bEcd6b4Fb076348A455518aea23d3799361FE95',
)

export const PENDLE_PT_WE_ETH_JUN_2024_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x1c27Ad8a19Ba026ADaBD615F6Bc77158130cfBE4',
)

export const PENDLE_PT_WE_ETH_SEP_2024_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xb8b0a120f6a68dd06209619f62429fb1a8e92fec',
)

export const PENDLE_PT_WST_ETH_JUN_2024_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x9741CAc1a22Ff3615FA074fD0B439975a5E137e9',
)

export const PENDLE_PT_WST_ETH_JUN_2025_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x1255638EFeca62e12E344E0b6B22ea853eC6e2c7',
)

export const PENDLE_YT_GLP_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x56051f8e46b67b4d286454995dBC6F5f3C433E34',
)

export const PENDLE_PT_EZ_ETH_JUN_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtEzETHJun2024IsolationModeVaultFactory,
)

export const PENDLE_PT_EZ_ETH_SEP_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtEzETHSep2024IsolationModeVaultFactory,
)

export const PENDLE_PT_GLP_MAR_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtGLPMar2024IsolationModeVaultFactory,
)

export const PENDLE_PT_GLP_SEP_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtGLPSep2024IsolationModeVaultFactory,
)

export const PENDLE_PT_METH_DEC_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtmETHDec2024IsolationModeVaultFactory,
)

export const PENDLE_PT_MNT_OCT_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtMntOct2024IsolationModeVaultFactory,
)

export const PENDLE_PT_R_ETH_JUN_2025_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtREthJun2025IsolationModeVaultFactory,
)

export const PENDLE_PT_RS_ETH_SEP_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtRsETHSep2024IsolationModeVaultFactory,
)

export const PENDLE_PT_USDE_DEC_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtUSDeDec2024IsolationModeVaultFactory,
)

export const PENDLE_PT_USDE_JUL_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtUSDeJul2024IsolationModeVaultFactory,
)

export const PENDLE_PT_WE_ETH_APR_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtWeETHApr2024IsolationModeVaultFactory,
)

export const PENDLE_PT_WE_ETH_JUN_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtWeETHJun2024IsolationModeVaultFactory,
)

export const PENDLE_PT_WE_ETH_SEP_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtWeETHSep2024IsolationModeVaultFactory,
)

export const PENDLE_PT_WST_ETH_JUN_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtWstEthJun2024IsolationModeVaultFactory,
)

export const PENDLE_PT_WST_ETH_JUN_2025_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendlePtWstEthJun2025IsolationModeVaultFactory,
)

export const PENDLE_YT_GLP_MAR_2024_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PendleYtGLPMar2024IsolationModeVaultFactory,
)

export const PLV_GLP_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x5326E71Ff593Ecc2CF7AcaE5Fe57582D6e74CFF1',
)

export const PLV_GLP_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.PlutusVaultGLPIsolationModeVaultFactory,
)

export const PLV_GLP_FARM_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x4E5Cf54FdE5E1237e80E87fcbA555d829e1307CE',
)

export const R_ETH_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xEC70Dcb4A1EFa46b8F2D97C310C9c4790ba5ffA8',
)

export const RS_ETH_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x4186BFC76E2E237523CBC30FD220FE055156b41F',
)

export const S_GLP_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x5402B5F40310bDED796c7D0F3FF6683f5C0cFfdf',
)

/**
 * The fsGLP token
 */
export const STAKED_GLP_REWARD_TRACKER_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x1aDDD80E6039594eE970E5872D247bf0414C8903',
)

export const STAKED_GMX_REWARD_TRACKER_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0x908C4D94D34924765f1eDc22A1DD098397c59dD4',
)

export const USDM_ROUTER_ADDRESSES = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  '0xa9B377B97395C4031750cE8E9397c00c577F6C6D',
)

export const WMNT_ISOLATION_MODE_ADDRESSES = initializeStringOptChainIdMap(
  ModuleDeployments.MNTIsolationModeVaultFactory,
)

const WRAPPED_CURRENCY_ONLY: ChainIdMap<Token[] | undefined> = {
  [ChainId.MAINNET]: [WRAPPED_CURRENCY[ChainId.MAINNET]],
  [ChainId.ARBITRUM_ONE]: [WRAPPED_CURRENCY[ChainId.ARBITRUM_ONE]],
  [ChainId.BASE]: [WRAPPED_CURRENCY[ChainId.BASE]],
  [ChainId.BERACHAIN]: [WRAPPED_CURRENCY[ChainId.BERACHAIN]],
  [ChainId.MANTLE]: [WRAPPED_CURRENCY[ChainId.MANTLE]],
  [ChainId.POLYGON_ZKEVM]: [WRAPPED_CURRENCY[ChainId.POLYGON_ZKEVM]],
  [ChainId.X_LAYER]: [WRAPPED_CURRENCY[ChainId.X_LAYER]],
}

export const STABLECOINS = Object.keys(initializeObjectChainIdMap()).reduce((memo, chainIdString) => {
  const chainId = parseInt(chainIdString) as ChainId
  const stablecoins = [USDC[chainId], USDT[chainId], GRAI[chainId], DAI[chainId], MIM[chainId], BRIDGED_USDC[chainId]]
  memo[chainId] = stablecoins.filter((s): s is Token => s !== undefined)

  return memo
}, {} as ChainIdMap<Token[]>)

/**l
 * Some tokens can only be swapped via certain pairs, so we override the ist of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: Record<string, Token[]> } = {}

// used to construct the list of all pairs we consider by default in the frontend
// TODO: delete this once AMM pools are gone
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WRAPPED_CURRENCY_ONLY,
  [ChainId.ARBITRUM_ONE]: [
    ...(WRAPPED_CURRENCY_ONLY[ChainId.ARBITRUM_ONE] ?? []),
    DAI[ChainId.ARBITRUM_ONE]!,
    USDC[ChainId.ARBITRUM_ONE],
    BRIDGED_USDC[ChainId.ARBITRUM_ONE]!,
    WBTC[ChainId.ARBITRUM_ONE]!,
  ],
}

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_ONE = JSBI.BigInt(1)
export const BIG_INT_TWO = JSBI.BigInt(2)

// export const MAX_UINT_256 = JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
export const MAX_UINT_256 = JSBI.BigInt(
  '115792089237316195423570985008687907853269984665640564039457584007913129639935',
)

export const NEGATIVE_TWO_UINT_256 = JSBI.BigInt(
  '115792089237316195423570985008687907853269984665640564039457584007913129639934',
)

// one basis point
export const ONE_BIPS = new Percent(BIG_INT_ONE, JSBI.BigInt(10000))
export const TEN_BIPS = new Percent(JSBI.BigInt(10), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non-expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH, so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))
export const ONE_ETH_IN_WEI = JSBI.BigInt('1000000000000000000')
export const ONE_DOLLAR_IN_VALUE = JSBI.BigInt('1000000000000000000000000000000000000')
export const DAYS_IN_YEAR = new Fraction('365')
export const ONE_YEAR_SECONDS = DAYS_IN_YEAR.multiply(86_400)
export const LP_FEES = new Fraction('30', '10000')

export const ZERO_FRACTION = new Fraction('0')
export const MAX_FRACTION = new Fraction(MaxUint256)
export const ONE_FRACTION = new Fraction('1')
export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const DEFAULT_MIN_COLLATERALIZATION = new Fraction('115', '100')
export const DEFAULT_LIQUIDATION_REWARD = new Fraction('105', '100')

export const SECONDS_PER_YEAR = 31_536_000

export const SECONDS_PER_WEEK = 604_800

/**
 * SDN OFAC addresses
 */
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
]

export const DEFAULT_CHUNK_GAS_REQUIRED = 200_000
export const CHUNK_GAS_LIMIT = 100_000_000
